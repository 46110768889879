import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faHome, faPhone } from "@fortawesome/free-solid-svg-icons"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div className="container max-w-screen-xl py-14 text-center flex flex-col items-center justify-center">
      <h1 className="text-4xl font-bold mb-4">404 - Page Not Found</h1>
      <h4 className="text-charcoal mb-8">
        Oops! It seems the page you're looking for doesn't exist.
      </h4>
      <p className="text-charcoal mb-6">
        If you need help, feel free to contact us directly so we can assist you:
        <br />
        <a
          href="tel:401-526-0055"
          className="mt-4 text-blue font-semibold inline-flex items-center"
        >
          <FontAwesomeIcon icon={faPhone} className="mr-2" />
          401-526-0055
        </a>
        <br />
      </p>

      <p className="mb-6 text-charcoal">
        You can also send us a note here:{" "}
        <Link to="/contact-us" className="text-blue">
          Contact us
        </Link>
      </p>

      <p className="text-charcoal">
        Or you can go back to our homepage:
        <br />
        <Link
          to="/"
          className="mt-4 bg-blue hover:bg-royal-blue text-white hover:text-white px-6 py-3 rounded-full inline-flex items-center hover:bg-blue-600 transition-all"
        >
          <FontAwesomeIcon icon={faHome} className="mr-2" />
          Back to Home
        </Link>
      </p>
    </div>
  </Layout>
)

export default NotFoundPage
